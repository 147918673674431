.headCard {
  border-radius: 13px;
  //   box-shadow: rgba(100 100 111 / 20%) 0px 7px 29px 0px;
  box-shadow: #d5d5da 0px 7px 11px 0px;
  border: none;
  .headerCard {
    background-color: #20c997;
    padding: 30px !important;
    border-radius: 12px 12px 0px 0px;
  }
  .indiCard {
    margin-top: -29px;
    margin-bottom: 29px;
    box-shadow: #d5d5da 0px 0px 3px 0px;
    border: none;
    .singleCard {
      display: flex;
      justify-content: space-between;
    }
  }
  .insideCard {
    box-shadow: #d5d5da 0px 0px 3px 0px;
    border: none;
  }
  .singleCard {
    display: flex;
    justify-content: space-between;
  }
}
.dashboardImg {
  border-radius: 14px;
  box-shadow: #d5d5da 0px 14px 18px 0px;
}

.modal_popup{
    height: 90vh !important;
}


// .modal-dialog{
//     border: 2px solid red;
//     width:100%;
//     height: 90vh;
//     margin:0;
// }

.fade{
display: flex;
}

.modal_popup{

.modal-content {
    width:70vw;
    height: 100%;
}
// /* custom-styles.css */
.modal {
    
    top: 0;
    left: 0;
    width: 100vw !important;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1055;
    overflow: hidden;
  }
  
  .modal-dialog {
    width: 70%;
    height: 90%;
    max-width: 70%;
    max-height: 100%;
  }
  
  @media (max-width: 768px) {
    .modal-dialog {
      width: 90%;
      height: 100%;
    }
  }
  
  @media (max-width: 576px) {
    .modal-dialog {
      width: 95%;
      height: 100%;
    }
  }
  
 
}

  




// ======================================================

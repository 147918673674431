.authcomponent {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0px -15px;
  .bgleftimage {
    height: 100vh;
    width: 40%;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .bgrightloginform {
    width: 100%;
    @media only screen and (min-width: 785px) {
      width: 60%;
    }
    .formBox {
      width: 50%;
      margin: auto;
      @media only screen and (max-width: 785px) {
        width: 80%;
      }
    }
    .eyeStyle {
      opacity: 0.6;
      cursor: pointer;
    }
    .keepLogged {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px;
    }
  }
}

.customIcon {
  background: none !important;
  border-left: 0 !important;
}

.customNote {
  color: #5a5f7d;
  font-size: 13px;
}

.usermainpage {
  // background: #faf9f7;
  margin-top: 20px;
  .author {
    text-align: center;
  }
  .userHeader {
    display: flex;
    h6 {
      margin-right: 40px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
  .contactInfo {
    margin-left: 20px;
  }
  .headText {
    margin-top: 20px;
    margin-left: 20px;
  }
  .form-control {
    width: 34% !important;
    border: 1px solid #f8f9fa !important;
  }
}
.card-header {
  background-color: #faf9f7 !important;
  border-bottom: 0 !important;
}
.btnStyle {
  display: flex;
  .cancelBtn {
    margin-left: 20px !important;
  }
}
.actionBtn {
  opacity: 0.5 !important;
  margin-right: 10px !important;
  cursor: pointer !important;
}
.coverImg {
  border-radius: 10px;
  width: 100%;
}
.customtd {
  border-bottom-width: 0px !important;
  border-bottom: 0 !important;
  display: flex;
  div {
    margin-left: 40px;
  }
  img {
    width: 10%;
    height: 0%;
  }
  .sideImg {
    margin: 12px 14px 0px -5px;
  }
  &:hover {
    background: #f8f9fb !important;
    color: #5a5f7d;
  }
}
.driverList {
  .fixImg {
    display: flex;
    margin-bottom: -24px;
    p {
      opacity: 0.7;
    }
  }
  img {
    width: 22%;
    height: 0%;
  }
}

// new css
.customCard {
  border-radius: 10px !important;
  box-shadow: 0px 0px 3px 0px lightgray;
  margin-bottom: 20px !important;
  border: none !important;
  .borderImg {
    border-radius: 50%;
  }

  .onListHover {
    &:hover {
      box-shadow: 0px 15px 50px #9299b826;
      border-radius: 4px;
      opacity: 1;
    }
  }
}

.searchContainer{
  height:40px;
  padding: 0;
  display: flex;

}
.searchBar {
  display:block;
  width:300px;
  height:40px;
  box-shadow: none !important;
  border: 1px solid #e9ecef !important;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
  outline:none;
  padding:10px;
}

.searchButton{
  width:40px;
  height:40px;
  background-color: #fbbb00;
  color:white;
  cursor: pointer ;
  border:none;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
}
.searchIcon{
 
}
.userSearchText {
  color: #212529;
  font-size: 17px;
  font-weight: 400;
  margin-top: 20px !important;
}
.customCardHeader {
  background: none !important;
  padding: 15px 1rem 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.controlPadding {
    padding: 10px 1rem 10px;
  }
  .headerMaintext {
    letter-spacing: 0px;
    color: #272b41;
    opacity: 1;
    font-size: 15px;
  }

  .headersecondtext {
    letter-spacing: 0px;
    color: #9299b8;
    opacity: 1;
    font-size: 12px;
  }
}

.customCardFooter {
  padding: 0.5rem 1rem 25px;
  .postBooking {
    justify-content: space-between;
    align-items: center;
  }
  &.footerPadding {
    margin: 0 25px;
    padding: 0.5rem 0.8rem 15px;
    @media (max-width: 1000px) {
      margin: 0 15px;
      padding: 0.5rem 0.5rem 15px;
    }
  }
  background: none !important;
  .customButton {
    text-align: left;
    height: 46px;
    border-radius: 6px;
    letter-spacing: 0px;
    color: #9299b8;
    font-size: 14px;
    box-shadow: none !important;
    &.focused {
      color: #f7b217;
      background: #fffbf3;
      box-shadow: none !important;
    }
    .posEditAcc {
      position: relative;
      top: -1px;
      margin-right: 3px;
    }
  }
}
.statusColor {
  color: greenyellow;
}

.userNametext {
  letter-spacing: 0px;
  color: #272b41;
  opacity: 1;
  font-size: 18px;
  font-weight: 400;
  text-transform: capitalize;
  &.textSize {
    font-size: 16px;
    font-weight: 500;
  }
}

.userTypeText {
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0px;
  color: #9299b8;
  opacity: 1;
  text-transform: capitalize;
}

.contactHeadText {
  letter-spacing: 0px;
  color: #9299b8;
  text-transform: uppercase;
  opacity: 1;
  font-size: 12px;
  font-weight: 300;
}

.contactBodyText {
  letter-spacing: 0px;
  color: #5a5f7d;
  opacity: 1;
  font-size: 14px;
  font-weight: 300;
  .sideIcons {
    opacity: 0.5;
  }
}

.listHover {
  &:hover {
    border-radius: 4px;
    box-shadow: 0px 15px 50px #9299b833 !important;
  }
}
.listbodyText {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0px;
  color: #272b41;
  opacity: 1;
  text-transform: capitalize;
  width: 210px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 1000px) {
    width: 90px;
  }
  @media (max-width: 600px) {
    width: 140px;
  }
}
.listbodyTextVehicle {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0px;
  color: #272b41;
  opacity: 1;
  text-transform: capitalize;
  width: 210px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 1000px) {
    width: 75px;
  }
  @media (max-width: 600px) {
    width: 140px;
  }
}
.listlocationText {
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0px;
  color: #9299b8;
  opacity: 1;
  text-transform: capitalize;
}

.closeAccount {
  width: 131px;
  height: 38px;
  letter-spacing: 0px;
  color: #ffffff;
  box-shadow: 0px 3px 5px #20c9970d;
  opacity: 1;
  font-size: 13px;
  background: #ff4d4f;
  padding: 12px;
  font-weight: 400;
}

.imageContainer {
  position: relative;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  .profileImage {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }

  .cameraIcon {
    position: absolute;
    width: 30px;
    height: 30px;
    background: #fbbb00;
    padding: 2px;
    border-radius: 50%;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    left: 50%;
    top: 15%;
    // left: 85%;
    // top: 40%;
    transform: translate(-50%, 100%);
    transition: all 1s;
    opacity: 0;
  }
}

.imageContainer:hover .cameraIcon {
  transform: translate(-50%, 100%);
  opacity: 2;
}

.imageContainer:hover .profileImage {
  opacity: 0.5;
}

.modalImage {
  border-radius: 50%;
  &:hover {
    box-shadow: 0px 15px 50px #9299b833 !important;
  }
}

.emailText {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0px;
  color: #272b41;
  opacity: 1;
}

.walletHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: none !important;
  padding: 15px 1rem 15px;
  flex-wrap: wrap;
  .manageDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    &.iconDiv {
      width: 50px;
      height: 50px;
      border-radius: 10px;
      background: #d7faf0;
      .customIcon {
        color: #20c997;
        font-size: 25px;
      }
    }
    .contentDiv {
      h2 {
        color: #272b41;
        font-size: 30px;
      }
      h6 {
        color: #868eae;
        font-size: 15px;
        margin: 0 !important;
        font-weight: 400 !important;
      }
    }
  }
}

.modifyTable {
  margin: 0 !important;
  white-space: nowrap;
  .tablehead {
    .headText {
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0px;
      color: #272B41;
      opacity: 1;
      padding: 0 0 .5rem !important;
    }
  }

  .tableBody {
    .bodyText {
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0px;
      color: #5A5F7D;
      opacity: 1;
      text-transform: capitalize;
      padding: .5rem 0 !important;
    }
    &:not(:first-child) {
      border: inherit !important;
    }
    tr {
      &:last-child {
        border: 1px solid transparent !important;
      }
    }
    
  }
}

.customDisabledBtn {
  opacity: unset !important;
}



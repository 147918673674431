.vehicleContainer {
  width: 100%;
  height: 90vh;
}

.customCard {
  border-radius: 10px !important;
  box-shadow: 0px 0px 3px 0px lightgray;
  margin-bottom: 20px !important;
  border: none !important;
}

.textHeader {
  display: flex;
  gap: 10px;
  align-items: center;
}

.addButton {
  background: #fbbb00 !important;
  border-color: #fbbb00 !important;
  color: #fff;
  border-radius: 4px;
  width: 115px;
  height: 40px;
  font-size: 13px;
  font-weight: 600;

  &:hover {
    background: #fbbb00;
    border-color: #fbbb00;
    color: #fff;
  }

  &:focus {
    box-shadow: none !important;
  }
}

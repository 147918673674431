$button_height:85%;

.usermainpage {
  // background: #faf9f7;
  margin-top: 20px;

  .author {
    text-align: center;
  }

  .userHeader {
    display: flex;

    h6 {
      margin-right: 40px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .contactInfo {
    margin-left: 20px;
  }

  .headText {
    margin-top: 20px;
    margin-left: 20px;
  }

  .form-control {
    width: 34% !important;
    border: 1px solid #f8f9fa !important;
  }
}

.card-header {
  background-color: #faf9f7 !important;
  border-bottom: 0 !important;
}

.btnStyle {
  display: flex;

  .cancelBtn {
    margin-left: 20px !important;
  }
}

.actionBtn {
  opacity: 0.5 !important;
  margin-right: 10px !important;
  cursor: pointer !important;
}

.coverImg {
  border-radius: 10px;
  width: 100%;
}

.customtd {
  border-bottom-width: 0px !important;
  border-bottom: 0 !important;
  display: flex;

  div {
    margin-left: 40px;
  }

  img {
    width: 10%;
    height: 0%;
  }

  .sideImg {
    margin: 12px 14px 0px -5px;
  }

  &:hover {
    background: #f8f9fb !important;
    color: #5a5f7d;
  }
}

.driverList {
  .fixImg {
    display: flex;
    margin-bottom: -24px;

    p {
      opacity: 0.7;
    }
  }

  img {
    width: 22%;
    height: 0%;
  }
}

// new css
.customCard {
  border-radius: 10px !important;
  box-shadow: 0px 0px 3px 0px lightgray;
  margin-bottom: 20px !important;
  border: none !important;

  .borderImg {
    border-radius: 50%;
  }

  .onListHover {
    &:hover {
      box-shadow: 0px 15px 50px #9299b826;
      border-radius: 4px;
      opacity: 1;
    }
  }
}

.searchBar {
  box-shadow: none !important;
  border: 1px solid #e9ecef !important;
  width: 40%;
  border-radius: 13px;
  margin: 13px 0px 0px 37px;
}

.userSearchText {
  color: #212529;
  font-size: 17px;
  font-weight: 400;
  margin-top: 20px !important;
}

.customCardHeader {
  background: none !important;
  padding: 15px 1rem 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.controlPadding {
    padding: 10px 1rem 10px;
  }

  .headerMaintext {
    letter-spacing: 0px;
    color: #272b41;
    opacity: 1;
    font-size: 15px;
  }

  .headersecondtext {
    letter-spacing: 0px;
    color: #9299b8;
    opacity: 1;
    font-size: 12px;
  }
}

.customCardFooter {
  padding: 0.5rem 1rem 25px;

  .postBooking {
    justify-content: space-between;
    align-items: center;
  }

  &.footerPadding {
    margin: 0 25px;
    padding: 0.5rem 0.8rem 15px;

    @media (max-width: 1000px) {
      margin: 0 15px;
      padding: 0.5rem 0.5rem 15px;
    }
  }

  background: none !important;

  .customButton {
    text-align: left;
    height: 46px;
    border-radius: 6px;
    letter-spacing: 0px;
    color: #9299b8;
    font-size: 14px;
    box-shadow: none !important;

    &.focused {
      color: #f7b217;
      background: #fffbf3;
      box-shadow: none !important;
    }

    .posEditAcc {
      position: relative;
      top: -1px;
      margin-right: 3px;
    }
  }
}

.statusColor {
  color: greenyellow;
}

.userNametext {
  letter-spacing: 0px;
  color: #272b41;
  opacity: 1;
  font-size: 18px;
  font-weight: 400;
  text-transform: capitalize;

  &.textSize {
    font-size: 16px;
    font-weight: 500;
  }
}

.userTypeText {
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0px;
  color: #9299b8;
  opacity: 1;
  text-transform: capitalize;
}

.contactHeadText {
  letter-spacing: 0px;
  color: #9299b8;
  text-transform: uppercase;
  opacity: 1;
  font-size: 12px;
  font-weight: 300;
}

.contactBodyText {
  letter-spacing: 0px;
  color: #5a5f7d;
  opacity: 1;
  font-size: 14px;
  font-weight: 300;

  .sideIcons {
    opacity: 0.5;
  }
}

.listHover {
  &:hover {
    border-radius: 4px;
    box-shadow: 0px 15px 50px #9299b833 !important;
  }
}

.listbodyText {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0px;
  color: #272b41;
  opacity: 1;
  text-transform: capitalize;
  width: 210px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 1000px) {
    width: 90px;
  }

  @media (max-width: 600px) {
    width: 140px;
  }
}

.listbodyTextVehicle {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0px;
  color: #272b41;
  opacity: 1;
  text-transform: capitalize;
  width: 210px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 1000px) {
    width: 75px;
  }

  @media (max-width: 600px) {
    width: 140px;
  }
}

.listlocationText {
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0px;
  color: #9299b8;
  opacity: 1;
  text-transform: capitalize;
}

.closeAccount {
  width: 131px;
  height: 38px;
  letter-spacing: 0px;
  color: #ffffff;
  box-shadow: 0px 3px 5px #20c9970d;
  opacity: 1;
  font-size: 13px;
  background: #ff4d4f;
  padding: 12px;
  font-weight: 400;
}

.imageContainer {
  position: relative;
  display: inline-block;
  text-align: center;
  border-radius: 50%;

  .profileImage {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }

  .cameraIcon {
    position: absolute;
    width: 30px;
    height: 30px;
    background: #fbbb00;
    padding: 2px;
    border-radius: 50%;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    left: 50%;
    top: 15%;
    // left: 85%;
    // top: 40%;
    transform: translate(-50%, 100%);
    transition: all 1s;
    opacity: 0;
  }
}

.imageContainer:hover .cameraIcon {
  transform: translate(-50%, 100%);
  opacity: 2;
}

.imageContainer:hover .profileImage {
  opacity: 0.5;
}

.modalImage {
  border-radius: 50%;

  &:hover {
    box-shadow: 0px 15px 50px #9299b833 !important;
  }
}

.bookingIDcell {
  min-width: 50px;
}

.buttonDiv {
  height:100%;
  display:flex;
  justify-content: center;
  align-items: center;


  .button {
    margin:auto;
    display:flex;
    justify-content:center;
    align-items: center;
    width: 77px;
    height: 34px;
    background: #FF4D4F 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 5px #20C9970D;
    border-radius: 3px;
    border: none;
    color: #ffffff;
    opacity: 1;
    
  }

  ;

  .paymentHoldButton {
    display: block;
    height:34px;
    justify-content: center;
    align-items: center;
    background: #C6D0DC 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 5px #20C9970D;
    border-radius: 3px;
    border: none;
    color: #ffffff;
    opacity: 1;
  }

  .paymentHoldButton p{
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin:auto;

  }
  
}

;

.amountDiv {
  margin-left: 20px;
}

.successStatus {
  display: flex;
  position: relative;
  text-align: center;
  justify-content: center;
  background:rgba(32, 201, 151, 0.1) 0% 0% no-repeat padding-box;
  border-radius: 15px;
  // padding: 5px 15px 5px 15px !important;
  padding:0px 15px !important;
  margin-top:3px;
};

.successStatusText {
  color: #20C997;
  opacity: 1;
  // font-size: 12px;
  font-size: 14px;
  font-weight:500;
};

.processingStatus{
  display: flex;
  position: relative;
  text-align: center;
  justify-content: center;
  background: rgba(44, 153, 255, 0.1)  0% 0% no-repeat padding-box;
  border-radius: 15px;
  // padding: 5px 15px 5px 15px !important;
  padding:0px 15px !important;
  margin-top:3px;
};

.processingStatusText{
  color: #2C99FF;
  opacity: 1;
  // font-size: 12px;
  font-size: 14px;
  font-weight:500;
};

.holdStatus{
  display: flex;
  position: relative;
  text-align: center;
  justify-content: center;
  background: rgba(90,95,125,.1) 0 0 no-repeat padding-box;;
  border-radius: 15px;
  // padding: 5px 15px 5px 15px !important;
  padding:0px 15px !important;
  margin-top:3px;
};

.holdStatusText{
  color: #5a5f7c;
  opacity: 1;
  // font-size: 12px;
  font-size: 14px;
  font-weight:500;
};

.failedStatus{
  display: flex;
  position: relative;
  text-align: center;
  justify-content: center;
  background: rgba(255,77,79,.1) 0 0 no-repeat padding-box;
  border-radius: 15px;
  // padding: 5px 15px 5px 15px !important;
  padding:0px 15px !important;
  margin-top:3px;
};

.failedStatusText{
  color: #ff4d4f;
  opacity: 1;
  // font-size: 12px;
  font-size: 14px;
  font-weight:500;
};

.refundedStatus{
  display: flex;
  position: relative;
  text-align: center;
  justify-content: center;
  background: rgba(247, 178, 23, 0.3)  0% 0% no-repeat padding-box;
  border-radius: 15px;
  // padding: 5px 15px 5px 15px !important;
  padding:0px 15px !important;
  margin-top:3px;
};

.refundedStatusText{
  color: #F7B217;
  opacity: 1;
  // font-size: 12px;
  font-size: 14px;
  font-weight:500;
};

.cancelledStatus{
  display: flex;
  position: relative;
  text-align: center;
  justify-content: center;
  background: #272b41 0 0 no-repeat padding-box;
  border-radius: 15px;
  // padding: 5px 15px 5px 15px !important;
  padding:0px 15px !important;
  margin-top:3px;
};

.cancelledStatusText{
  color: #fff;
  opacity: 1;
  // font-size: 12px;
  font-size: 14px;
  font-weight:500;
};

.paymentFailedDiv{
  // display: flex;
  // position: relative;
  // text-align: center;
  // justify-content: center;
  display:flex;
  justify-items: center;
  align-items: center;
  height:$button_height;
  margin:auto;
  background: rgba(255, 77, 79, 0.1)  0% 0% no-repeat padding-box;
  border-radius: 15px;
  font-size: 14px;
  padding: 5px 15px 5px 15px !important;
  
 

};

.paidToVendor{
  // display: flex;
  // position: relative;
  // text-align: center;
  // justify-content: center;
  height:$button_height;
  display:flex;
  justify-items: center;
  align-items: center;
  margin:auto;
  color: #ffffff;
  background: #20C997  0% 0% no-repeat padding-box;
  border-radius: 15px;
  padding: 5px 15px 5px 15px !important;
  font-size: 14px;
};

.refunded{
  display:block;
  margin:auto;
  color: #ffffff;
  background: #F7B217  0% 0% no-repeat padding-box;
  border-radius: 15px;
  // padding: 5px 15px 5px 15px !important;
  padding:0px 15px !important;
  font-size: 14px;
};

.success{
  display: flex;
  position: relative;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  background: #20c997 0 0 no-repeat padding-box;
  border-radius: 15px;
  // padding: 5px 15px 5px 15px !important;
  padding:0px 15px !important;
  font-size: 14px;
}

.cancelled{
  display:block;
  margin:auto;
  color: #ffffff;
  background: #272b41 0 0 no-repeat padding-box;
  border-radius: 15px;
  // padding: 5px 15px 5px 15px !important;
  padding:0px 15px !important;
  font-size: 14px;
}

.modalOterDiv {
  .modalmainDiv {
    .modalDiv {
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 1rem 1rem 0rem 1rem;

      .modalTitle {
        color: #272b41;
        font-size: 16px;
        font-weight: 600;
        margin-top: 2%;
      }

      .closeButtonDiv {
        height: 34px;
        width: 34px;
        display: flex;
        justify-content: center;
        margin-top: 5px;
        border-radius: 50%;
        background-color: #f4f5f7;
        .closeButton {
          color: #9299b8;
          font-size: 20px;
          font-weight: 400;
          cursor: pointer;
        }
      }
    }
  }
  
  .modalBody {
    .midSection {
      .addPlanDiv {
        .addPlanText {
          font-size: 14px;
          color: #272b41;
          font-weight: 600;
        }

        .addPlanInput {
          margin-top: 5px;
          width: 100%;
          height: 48px;
          padding-left: 13px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #e3e6ef;
          border-radius: 5px;
        }

        ::placeholder {
          color: #adb4d2;
        }
      }
      .paymentButtonDiv {
        margin-top: 20px;
        display: flex;
        gap: 20px;

        .uploadButton {
          height: 45px;
          color: white;
          border: none;
          background: #f7b217 0% 0% no-repeat padding-box;
          box-shadow: 0px 5px 20px #5f63f21a;
          border-radius: 5px;
        }

        .cancelButton {
          width: 86px;
          height: 44px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #e3e6ef;
          border-radius: 5px;
        }
      }
    }
  }
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, .bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #455a64 !important;
  border-width: .4rem .4rem .4rem 0;
}

.tooltip-inner{
  background-color: #455a64;
}

.infoOverlayDiv{
  display: flex;
  flex-direction: row;
  >div:first-child{
    flex-grow: 0;
  }
  >div:nth-child(2){
    flex-grow: 1;
  }
}
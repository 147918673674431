.userCountText {
  color: #212529;
  font-size: 17px;
  font-weight: 400;
  margin-top: 20px !important;
}

.customCard {
  border-radius: 10px !important;
  box-shadow: 0px 0px 3px 0px lightgray;
  margin-bottom: 20px !important;
  border: none !important;
}


.tab-content{
  margin-top: 10px;
}
.cunstomNavs {
 
  li {
    padding: 0 0 5px;
    margin-right: 20px;
    font-size: 16px;
    a {
      color: #9299b8 !important;
    }
    &.active {
      border-bottom: 2px solid #f7b217;
      color: #f7b217;
      font-weight: 500;
      a {
        color: #f7b217 !important;
      }
    }
  }
}

.searchField {
  box-shadow: none !important;
  border: 1px solid #e9ecef !important;
  border-radius: 13px;
}

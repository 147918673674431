.customCard {
  border-radius: 10px !important;
  box-shadow: 0px 0px 3px 0px lightgray;
  margin-bottom: 20px !important;
  border: none !important;
  .searchBar {
    box-shadow: none;
    border: 1px solid #e9ecef;
    width: 40%;
    border-radius: 13px;
  }
}

.customCard {
  border-radius: 10px !important;
  box-shadow: 0px 0px 3px 0px lightgray;
  margin-bottom: 20px !important;
  border: none !important;
}

.customText {
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0px;
  color: #5a5f7d;
  opacity: 1;
}
.actionEdit {
  opacity: 0.5;
  cursor: pointer;
}

.searchBar {
    box-shadow: none !important;
    border: 1px solid #e9ecef !important;
    width: 40%;
    border-radius: 13px;
    margin: 13px 0px 0px 37px;
}
.bookingSearchText {
    color: #212529;
    font-size: 17px;
    font-weight: 400;
    margin-top: 20px !important;
}

.bodyText{
    height:100%;
    // display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.chipBodyText{
    height:100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

// .customCard {
//     border-radius: 10px !important;
//     // box-shadow: 0px 0px 3px 0px lightgray;
//     margin-bottom: 20px !important;
//     border: none !important;
//   }

.customDisabledBtn {
    opacity: unset !important;
    position: absolute;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.isSecure {
    path {
        stroke: green !important;
    }
}

.isnotSecure {
    path {
        stroke: red !important;
    }
}

.statusDiv {
    border-radius: 20px;
    width:100%;
    height: 30px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 10px;
    font-weight: 600;
    // font-family: "Roboto Condensed", sans-serif;;
    &.new {
        color: #5A5F7D;
        background-color: #5a5f7d2f;
    }
    &.expired {
        color: #FF4D4F;
        background-color: #ff4d5033;
    }
    &.cancel {
        color: orange;
        background-color: rgba(255, 166, 0, 0.13);
    }
    &.end {
        color: green;
        background-color: rgba(0, 128, 0, 0.185);
    }
    &.assigned {
        color: #2C99FF;
        background-color: #2c99ff33; 
    }
    &.picked {
        color: rgb(82, 82, 0);
        background-color: rgba(255, 255, 0, 0.397); 
    }
    &.deleted {
        color: #cc0099;
        background-color: #cc00cc28; 
    }
}

.infoOverlayDiv{
    display: flex;
    flex-direction: row;
    >div:first-child{
      flex-grow: 0;
    }
    >div:nth-child(2){
      flex-grow: 1;
    }
  }
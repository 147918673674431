.customCard {
    border-radius: 10px !important;
    box-shadow: 0px 0px 3px 0px lightgray;
    margin-bottom: 20px !important;
    border: none !important;
}


.exportContainer{
    width:100%;
    height:90vh;
}

.exportData {
    color: #272B41;
    font-Size: 20px;
    font-family: "Roboto Condensed", sans-serif;;
    margin-top: 20px;
    margin-Bottom: 30px;
}

.exportButtonDiv {
    display: flex;
    justify-content: space-between;
    margin: 20px 0px;
    input {
        border-radius: 20px;
        border: 2px solid whitesmoke;
    }
   input::placeholder {
      padding-left: 10px;
} 
}

.customDisabledBtn {
    opacity: unset !important;
}
html,
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #272b41;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
button:focus {
  outline: 0;
}


$fontFamily:"Roboto Condensed", sans-serif;


.customButtonActive {
  background: #fbbb00 !important;
  border-color: #fbbb00 !important;
  color: #fff;
  border-radius: 4px;
  width: 185px;
  height: 48px;
  &:hover {
    background: #fbbb00;
    border-color: #fbbb00;
    color: #fff;
  }
  &.customButtonFP {
    border-radius: 8px;
    width: 259px;
  }

  &.userProfile {
    width: 136px;
    height: 44px;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.customButtonCancle {
  background: #f8f9fb !important;
  border-color: #f1f2f6 !important;
  color: #5a5f7d;
  border-radius: 4px;
  width: 86px;
  height: 44px;
  &:hover {
    background: #f8f9fb !important;
    border-color: #f1f2f6 !important;
    color: #5a5f7d;
  }
  &:focus {
    box-shadow: none !important;
  }
}

a {
  text-decoration: none !important;
  &:hover {
    color: #272b41;
    text-decoration: none;
  }
  &.page-nav-link {
    color: #fbbb00;
    &:hover {
      color: #fbbb00;
    }
  }
}

.form-check-input:checked {
  background: #fbbb00;
  border-color: #fbbb00;
}

.form-group {
  .form-control {
    height: 48px;
    &.textArea {
      height: auto;
    }
    &.customloginFieldBorder {
      &:focus {
        border-color: #fbbb00 !important;
        box-shadow: none !important;
      }
    }
  }
}

.form-group {
  .form-select {
    height: 48px;
    &.customloginFieldBorder {
      &:focus {
        border-color: #fbbb00 !important;
        box-shadow: none !important;
      }
    }
  }
}

.form-group {
  .input-group {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    .form-control {
      height: 48px;
      border: none !important;
      &:focus {
        border-color: transparent !important;
        box-shadow: none !important;
      }
    }
    .input-group-text {
      border: none !important;
      background: none !important;
    }

    &.customloginFieldBorder {
      &:focus-within {
        border-color: #fbbb00 !important;
        box-shadow: none !important;
      }
    }
  }
}

.customPginationDiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 30px;
  .customText {
    font-size: 13px;
    margin: 0;
    margin-right: 5px;
    @media (max-width: 600px) {
      font-size: 12px;
    }
  }
  .customSelectWidth {
    width: 7%;
    border-radius: 10px;
    margin-right: 20px;
    @media (max-width: 1000px) {
      width: 11%;
    }
    @media (max-width: 600px) {
      width: 25%;
    }
    &:hover {
      border-color: #fbbb00 !important;
      box-shadow: none !important;
    }
    &:focus {
      border-color: #fbbb00 !important;
      box-shadow: none !important;
    }
  }

  .customButtons {
    border-radius: 10px;
    border: 1px solid #ced4da;
    &.buttonMargin {
      margin-right: 20px;
    }
    &:hover {
      border-color: #fbbb00 !important;
      box-shadow: none !important;
    }
    &:focus {
      border-color: #fbbb00 !important;
      box-shadow: none !important;
    }
  }
}

.proSidebar {
  height: 100vh !important;
  background: none;
  position: fixed !important;
  .pro-sidebar-inner {
    background: white !important;
    box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15) !important;
    .pro-sidebar-layout {
      height: 95vh !important;
      padding-bottom: 20px !important;
    }
    .pro-sidebar-layout .active {
      color: red;
    }
    .pro-icon-wrapper {
      background: white !important;
    }
  }
}

.navbar {
  border-bottom: 2px solid whitesmoke;
  position: fixed !important;
  background-color: white;
  z-index: 1000;
  width: 100vw !important;
}

.pro-icon {
  font-size: 20px;
}


.activeIcon{
  color:black;
  pointer-events: none;
}



.custumTable {
  margin: 0 !important;
  white-space: nowrap;
  .tablehead {
    background: #f8f9fb !important;
    border: 1px solid #f1f2f6 !important;
    .headText {
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0px;
      color: #5a5f7d;
      opacity: 1;
    }
  }

  .tableBody {
    .bodyText {
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0px;
      color: #272b41;
      opacity: 1;
      text-transform: capitalize;
      .bodyTextEllipsis {
        width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
      }
    }
    .locationText {
      font-weight: normal;
      font-size: 12px;
      letter-spacing: 0px;
      color: #9299b8;
      opacity: 1;
      text-transform: capitalize;
    }
  }
}

.dropdownbtn {
  .dropdown {
    .btn {
      padding: 0px !important;
      color: currentColor;
      background: none;
      border-color: transparent;
      margin-top: -2px;
    }
  }
}

.customModal {
  .modal-dialog {
    .modal-content {
      border-radius: 10px !important;
      .modal-header {
        padding: 1rem 1rem 1px !important;
        border: none !important;
        .modal-title {
          color: #272b41 !important;
          text-transform: capitalize;
          opacity: 1;
          font-size: 16px !important;
        }
        .btn-close {
          background-size: 10px !important;
          &:focus {
            box-shadow: none !important;
          }
        }
      }


      .customModalBody {
        max-height: 372px;
        overflow: auto;
      }

      .modal-footer {
        border: none !important;
        padding: 1px 0.75rem 1rem !important;
        .customButton {
          width: 80px;
          height: 39px;
          border-radius: 5px;
          opacity: 1;
          font-size: 14px;
          text-transform: capitalize;
          &.acceptButton {
            background-color: #f7b217;
            border: none;
            color: #ffffff;
            &:focus {
              box-shadow: none !important;
            }
            &:hover {
              box-shadow: none !important;
            }
          }
          &.cancleButton {
            border: 1px solid #e3e6ef;
            color: #9299b8;
            &:focus {
              box-shadow: none !important;
            }
            &:hover {
              box-shadow: none !important;
            }
          }
        }
      }
    }
  }
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #f8d481;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: #f7b217;
  color: #fff !important;
  a {
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem transparent;
}

.customTitleName {
  color: #272b41;
  font-size:1.5em;
  font-family: $fontFamily;
  margin: 20px 0px !important;
  
}


::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: lightgray;
}


// loader

.buttonLoader {
  width: 20px;
  height: 20px;
  margin-left: 20px;
  &::after {
    content: " ";
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.spinnerDiv {
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.spinner {
  width: 50px;
  height: 50px;
}

// .ag-theme-quartz{
//   background-color: red;
// }
.ag-theme-quartz .ag-header-cell {
  font-size: 16px;
font-family: $fontFamily;

}
// .ag-theme-quartz .ag-header{
//   display:block;
//   margin:auto;
// }

.ag-theme-quartz .ag-header-cell-label {
  overflow: hidden;
  text-overflow: ellipsis;
  display: contents;
}


//enables the cell text selection
.ag-theme-quartz .ag-cell {
  user-select: text;
}

.Pageheader{
  
    color: #272B41;
    font-Size: 20px;
    font-family: $fontFamily;
    margin-top: 20px;
    margin-Bottom: 30px;

}

.container{
  height:90vh;
  // border:1px solid red;
}

.main-header{
  width:100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header{
  // background-color: yellowgreen;
  display: flex;
  flex-direction: row;
  gap:5px;
  align-items: center;
  justify-content: space-between;
}

.searchContainer{
  
  display: flex;
  justify-content: center;
  align-items: center;


}



// /* custom-styles.css */
// .modal {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100vw;
//   height: 100vh;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: rgba(0, 0, 0, 0.5);
//   z-index: 1055;
//   overflow: hidden;
// }

// .modal-dialog {
//   width: 70%;
//   height: 90%;
//   max-width: 70%;
//   max-height: 100%;
// }

// @media (max-width: 768px) {
//   .modal-dialog {
//     width: 90%;
//     height: 100%;
//   }
// }

// @media (max-width: 576px) {
//   .modal-dialog {
//     width: 95%;
//     height: 100%;
//   }
// }

// .modal-content {
//   display: block;
//   margin: 0;
//   height:90vh;
  
// }

// .modal-body {
//   width: 100%;
//   height: 100%;
//   overflow-y: auto;
// }

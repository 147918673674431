* {
  letter-spacing: 0px;
}

.mainContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .bannerCardSlot {
    width: 450px;
    height: 260px;
    background: #ffffff;
    box-shadow: 0px 5px 20px #9299b808;
    border-radius: 10px;
    margin-right: 15px;
    margin-bottom: 20px;

    .bannerImage {
      height: 200px;
      width: 100%;
      border: 1px solid #eaeaea;
      border-radius: 10px;
    }

    .imageContainer {
      width: 100%;
      height: 200px;
      background-color: #e3e6ef;
      border: 1px dashed #5a5f7d;
      border-radius: 10px;

      .fiRCUpload {
        width: 30px;
        height: 30px;
        display: flex;
        margin: auto auto;
        margin-top: 100px;
        color: #272b41;
      }
    }

    .bannerInformation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 10px 0px 10px;

      .bannnerText {
        color: #272b41;
        text-transform: capitalize;
        font-size: 20px;
        font-weight: 600;
      }

      .uploadImageAndLink {
        font-size: 16px;
        font-weight: 400;
        color: #9299b8;
      }
    }
  }
}

.modalOterDiv {
  .modalmainDiv {
    .modalDiv {
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      border-radius: 10px;

      .modalTitle {
        color: #272b41;
        font-size: 16px;
        font-weight: 600;
        margin-top: 2%;
      }

      .closeButtonDiv {
        height: 34px;
        width: 34px;
        display: flex;
        justify-content: center;
        margin-top: 5px;
        border-radius: 50%;
        background-color: #f4f5f7;
        .closeButton {
          color: #9299b8;
          font-size: 20px;
          font-weight: 400;
          cursor: pointer;
        }
      }
    }
  }

  .modalBody {
    .midSection {
      .inputSection {
        width: 458px;
        height: 247px;
        background: #e3e6ef 0% 0% no-repeat padding-box;
        border: 1px dashed #5a5f7d;
        border-radius: 10px;
        text-align: center;

        .bannerImagePreview {
          height: 100%;
          width: 100%;
        }

        .bannerLabel {
          text-align: center;
          margin-top: 100px;
          text-decoration: underline;

          span {
            text-decoration: none !important;
          }
        }
        .uploadText {
          margin-left: 5px;
          text-decoration: none !important;
        }
      }

      .buttonDiv {
        margin-top: 20px;
        display: flex;
        gap: 20px;

        .uploadButton {
          height: 45px;
          color: white;
          border: none;
          background: #f7b217 0% 0% no-repeat padding-box;
          box-shadow: 0px 5px 20px #5f63f21a;
          border-radius: 5px;
        }

        .cancelButton {
          width: 86px;
          height: 44px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #e3e6ef;
          border-radius: 5px;
        }
      }

      .bannerLinkDiv {
        .bannerText {
          margin-top: 10px;
          font-size: 14px;
          color: #272b41;
          font-weight: 600;
        }

        .bannerInput {
          width: 460px;
          height: 48px;
          margin-top: -5px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #e3e6ef;
          border-radius: 5px;
        }

        ::placeholder {
          color: #adb4d2;
        }
      }

      .uploadSection {
        .bannerText {
          font-size: 14px;
          font-weight: 600;
          color: #272b41;
        }
      }
    }
  }
}

.customNotificationDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  .buttonDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    .addNewPlan {
      height: 40px;
      background: #f7b217 0% 0% no-repeat padding-box;
      padding: 5px;
      box-shadow: 0px 3px 5px #20c9970d;
      border-radius: 3px;
      padding: 8px 15px;
      opacity: 1;
      border: none;
      border-radius: 10px;
      color: #ffffff;
    }
  }
}

.modalOterDiv {
  .modalmainDiv {
    .modalDiv {
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 1rem 1rem 0rem 1rem;

      .modalTitle {
        color: #272b41;
        font-size: 16px;
        font-weight: 600;
      }

      .closeButtonDiv {
        height: 34px;
        width: 34px;
        display: flex;
        justify-content: center;
        border-radius: 50%;
        background-color: #f4f5f7;
        .closeButton {
          color: #9299b8;
          font-size: 20px;
          font-weight: 400;
          cursor: pointer;
        }
      }
    }
  }

  .modalBody {
    .midSection {
      .addPlanDiv {
        .addPlanText {
          font-size: 14px;
          color: #272b41;
          font-weight: 600;
        }

        .addPlanInput {
          margin-top: 5px;
          width: 100%;
          height: 48px;
          padding-left: 13px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #e3e6ef;
          border-radius: 5px;
        }

        ::placeholder {
          color: #adb4d2;
        }
      }
    }
  }
}

.customCard {
    border-radius: 10px !important;
    box-shadow: 0px 0px 3px 0px lightgray;
    margin-bottom: 20px !important;
    border: none !important;
}

.actionBtn {
    opacity: 0.5;
    margin-right: 10px;
    cursor: pointer;
  }
.marginTop {
    margin-top: 60px;
}

.wfit {
    width: fit-content;
}

.openMobileTrue {
    width: 22%;
}

.openMobileFalse {
    margin-left: 100px;
}

.navSearch {
    input{
        border: none;
        margin-left: 100px;
        width: 400px;
    }
}
.navbarText {
    display: flex;
    div {
        margin-right: 20px;
        &:last-child {
            margin-right: 0px;
        }
        img {
            width: 15px;
            height: 15px;
        }
    }
}

.dropdownbtn {
  .dropdown {
    .btn .btn-primary {
       padding: 0px !important;
     }
  }
}

.btn .btn-primary {
    color: transparent !important;
    background : none !important;
    border-color: transparent !important;
    padding: 0 !important;
}